const actions = {
  // SALES ACTIONS
  LIST_ORDERS_BEGIN: 'LIST_ORDERS_BEGIN',
  LIST_ORDERS_SUCCESS: 'LIST_ORDERS_SUCCESS',
  LIST_ORDERS_ERR: 'LIST_ORDERS_ERR',

  ORDER_DETAILS_BEGIN: 'ORDER_DETAILS_BEGIN',
  ORDER_DETAILS_SUCCESS: 'ORDER_DETAILS_SUCCESS',
  ORDER_DETAILS_ERR: 'ORDER_DETAILS_ERR',

  SALES_SUMMARY_REPORT_BEGIN: 'SALES_SUMMARY_REPORT_BEGIN',
  SALES_SUMMARY_REPORT_SUCCESS: 'SALES_SUMMARY_REPORT_SUCCESS',
  SALES_SUMMARY_REPORT_ERR: 'SALES_SUMMARY_REPORT_ERR',

  REJECT_RESELL_ORDER_BEGIN: 'REJECT_RESELL_ORDER_BEGIN',
  REJECT_RESELL_ORDER_SUCCESS: 'REJECT_RESELL_ORDER_SUCCESS',
  REJECT_RESELL_ORDER_ERR: 'REJECT_RESELL_ORDER_ERR',

  APPROVE_RESELL_ORDER_BEGIN: 'APPROVE_RESELL_ORDER_BEGIN',
  APPROVE_RESELL_ORDER_SUCCESS: 'APPROVE_RESELL_ORDER_SUCCESS',
  APPROVE_RESELL_ORDER_ERR: 'APPROVE_RESELL_ORDER_ERR',

  listOrdersBegin: () => {
    return {
      type: actions.LIST_ORDERS_BEGIN,
    };
  },

  listOrdersSuccess: (data) => {
    return {
      type: actions.LIST_ORDERS_SUCCESS,
      data,
    };
  },

  listOrdersErr: (err) => {
    return {
      type: actions.LIST_ORDERS_ERR,
      err,
    };
  },

  getOrderDetailsBegin: () => {
    return {
      type: actions.ORDER_DETAILS_BEGIN,
    };
  },

  getOrderDetailsSuccess: (data) => {
    return {
      type: actions.ORDER_DETAILS_SUCCESS,
      data,
    };
  },

  getOrderDetailsErr: (err) => {
    return {
      type: actions.ORDER_DETAILS_ERR,
      err,
    };
  },

  salesSummaryReportBegin: () => {
    return {
      type: actions.SALES_SUMMARY_REPORT_BEGIN,
    };
  },

  salesSummaryReportSuccess: (data) => {
    return {
      type: actions.SALES_SUMMARY_REPORT_SUCCESS,
      data,
    };
  },

  salesSummaryReportErr: (err) => {
    return {
      type: actions.SALES_SUMMARY_REPORT_ERR,
      err,
    };
  },
  rejectResellOrderBegin: () => {
    return {
      type: actions.REJECT_RESELL_ORDER_BEGIN,
    };
  },

  rejectResellOrderSuccess: (data) => {
    return {
      type: actions.REJECT_RESELL_ORDER_SUCCESS,
      data,
    };
  },

  rejectResellOrderErr: (err) => {
    return {
      type: actions.REJECT_RESELL_ORDER_ERR,
      err,
    };
  },
  approveResellOrderBegin: () => {
    return {
      type: actions.APPROVE_RESELL_ORDER_BEGIN,
    };
  },

  approveResellOrderSuccess: (data) => {
    return {
      type: actions.APPROVE_RESELL_ORDER_SUCCESS,
      data,
    };
  },

  approveResellOrderErr: (err) => {
    return {
      type: actions.APPROVE_RESELL_ORDER_ERR,
      err,
    };
  },
};

export default actions;
