import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const NotFound = lazy(() => import('../../container/pages/404'));
const TopupRequestsList = lazy(() => import('../../container/wallet'));
const MoneyFlowList = lazy(() => import('../../container/moneyFlow'));
const TopupRequestDetails = lazy(() => import('../../container/wallet/TopupRequestDetails'));

function MerchantGroupsRoute() {
  return (
    <Routes>
      <Route path="/" element={<TopupRequestsList />} />
      <Route path="/topup-requests" element={<TopupRequestsList />} />
      <Route path="/topup-requests/:id" element={<TopupRequestDetails />} />
      <Route path="/money-flow" element={<MoneyFlowList />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default MerchantGroupsRoute;
