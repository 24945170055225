import actions from './actions';

const {
  LIST_WALLET_TRANSACTIONS_BEGIN,
  LIST_WALLET_TRANSACTIONS_SUCCESS,
  LIST_WALLET_TRANSACTIONS_ERR,
  GET_WALLET_BALANCE_BEGIN,
  GET_WALLET_BALANCE_SUCCESS,
  GET_WALLET_BALANCE_ERR,
  LIST_TOPUP_REQUEST_BEGIN,
  LIST_TOPUP_REQUEST_SUCCESS,
  LIST_TOPUP_REQUEST_ERR,
  GET_TOPUP_REQUEST_DETAILS_BEGIN,
  GET_TOPUP_REQUEST_DETAILS_SUCCESS,
  GET_TOPUP_REQUEST_DETAILS_ERR,
  ADD_MANUAL_CREDIT_BEGIN,
  ADD_MANUAL_CREDIT_SUCCESS,
  ADD_MANUAL_CREDIT_ERR,
  ADD_MANUAL_DEBT_BEGIN,
  ADD_MANUAL_DEBT_SUCCESS,
  ADD_MANUAL_DEBT_ERR,
  REMOVE_MANUAL_BALANCE_DEBT_BEGIN,
  REMOVE_MANUAL_BALANCE_DEBT_SUCCESS,
  REMOVE_MANUAL_BALANCE_DEBT_ERR,
  AGENT_TO_MERCHANT_TRANSFER_BEGIN,
  AGENT_TO_MERCHANT_TRANSFER_SUCCESS,
  AGENT_TO_MERCHANT_TRANSFER_ERR,
  CHANGE_TOPUP_REQUEST_STATUS_BACK_TO_PENDING_BEGIN,
  CHANGE_TOPUP_REQUEST_STATUS_BACK_TO_PENDING_SUCCESS,
  CHANGE_TOPUP_REQUEST_STATUS_BACK_TO_PENDING_ERR,
} = actions;

const initState = {
  walletTransactions: [],
  loadingListWalletTransactions: false,
  errorListWalletTransactions: '',
  totalWalletTransactionsPages: 0,
  totalWalletTransactions: 0,
  loadingGetWalletBalance: false,
  errorGetWalletBalance: '',
  walletBalance: {},

  topupRequests: [],
  loadingListTopupRequests: false,
  errorListTopupRequests: '',
  totalTopupRequestsPages: 0,
  totalTopupRequests: 0,
  loadingGetTopupRequestDetails: false,
  errorGetTopupRequestDetails: '',
  topupRequestDetails: {},

  loadingAddManualCredit: false,
  errorAddManualCredit: '',
  loadingAddManualDebt: false,
  errorAddManualDebt: '',

  loadingRemoveManualBalanceDebt: false,
  errorRemoveManualBalanceDebt: '',

  loadingAgentToMerchantTransfer: false,
  errorAgentToMerchantTransfer: '',

  loadingChangeTopupRequestStatusToPending: false,
  errorChangeTopupRequestStatusToPending: '',
};

const walletsReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case LIST_WALLET_TRANSACTIONS_BEGIN:
      return {
        ...state,
        walletTransactions: [],
        loadingListWalletTransactions: true,
        errorListWalletTransactions: '',
        totalWalletTransactionsPages: 0,
        totalWalletTransactions: 0,
      };
    case LIST_WALLET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        walletTransactions: [...data.results],
        loadingListWalletTransactions: false,
        errorListWalletTransactions: '',
        totalWalletTransactionsPages: data.total_pages,
        totalWalletTransactions: data.total_count,
      };
    case LIST_WALLET_TRANSACTIONS_ERR:
      return {
        ...state,
        loadingListWalletTransactions: false,
        errorListWalletTransactions: err,
        totalWalletTransactionsPages: 0,
        totalWalletTransactions: 0,
      };

    case GET_WALLET_BALANCE_BEGIN:
      return {
        ...state,
        walletBalance: {},
        loadingGetWalletBalance: true,
        errorGetWalletBalance: '',
      };
    case GET_WALLET_BALANCE_SUCCESS:
      return {
        ...state,
        walletBalance: data,
        loadingGetWalletBalance: false,
        errorGetWalletBalance: '',
      };
    case GET_WALLET_BALANCE_ERR:
      return {
        ...state,
        walletBalance: {},
        loadingGetWalletBalance: false,
        errorGetWalletBalance: err,
      };

    case LIST_TOPUP_REQUEST_BEGIN:
      return {
        ...state,
        topupRequests: [],
        loadingListTopupRequests: true,
        errorListTopupRequests: '',
        totalTopupRequestsPages: 0,
        totalTopupRequests: 0,
      };
    case LIST_TOPUP_REQUEST_SUCCESS:
      return {
        ...state,
        topupRequests: [...data.results],
        loadingListTopupRequests: false,
        errorListTopupRequests: '',
        totalTopupRequestsPages: data.total_pages,
        totalTopupRequests: data.total_count,
      };
    case LIST_TOPUP_REQUEST_ERR:
      return {
        ...state,
        loadingListTopupRequests: false,
        errorListTopupRequests: err,
        totalTopupRequestsPages: 0,
        totalTopupRequests: 0,
      };
    case GET_TOPUP_REQUEST_DETAILS_BEGIN:
      return {
        ...state,

        topupRequestDetails: {},
        loadingGetTopupRequestDetails: true,
        errorGetTopupRequestDetails: '',
      };
    case GET_TOPUP_REQUEST_DETAILS_SUCCESS:
      return {
        ...state,
        topupRequestDetails: data,
        loadingGetTopupRequestDetails: false,
        errorGetTopupRequestDetails: '',
      };
    case GET_TOPUP_REQUEST_DETAILS_ERR:
      return {
        ...state,
        topupRequestDetails: {},
        loadingGetTopupRequestDetails: false,
        errorGetTopupRequestDetails: err,
      };

    case ADD_MANUAL_CREDIT_BEGIN:
      return {
        ...state,
        loadingAddManualCredit: true,
        errorAddManualCredit: '',
      };
    case ADD_MANUAL_CREDIT_SUCCESS:
      return {
        ...state,
        loadingAddManualCredit: false,
        errorAddManualCredit: '',
      };
    case ADD_MANUAL_CREDIT_ERR:
      return {
        ...state,
        loadingAddManualCredit: false,
        errorAddManualCredit: err,
      };
    case ADD_MANUAL_DEBT_BEGIN:
      return {
        ...state,
        loadingAddManualDebt: true,
        errorAddManualDebt: '',
      };
    case ADD_MANUAL_DEBT_SUCCESS:
      return {
        ...state,
        loadingAddManualDebt: false,
        errorAddManualDebt: '',
      };
    case ADD_MANUAL_DEBT_ERR:
      return {
        ...state,
        loadingAddManualDebt: false,
        errorAddManualDebt: err,
      };

    case REMOVE_MANUAL_BALANCE_DEBT_BEGIN:
      return {
        ...state,
        loadingRemoveManualBalanceDebt: true,
        errorRemoveManualBalanceDebt: '',
      };
    case REMOVE_MANUAL_BALANCE_DEBT_SUCCESS:
      return {
        ...state,
        loadingRemoveManualBalanceDebt: false,
        errorRemoveManualBalanceDebt: '',
      };
    case REMOVE_MANUAL_BALANCE_DEBT_ERR:
      return {
        ...state,
        loadingRemoveManualBalanceDebt: false,
        errorRemoveManualBalanceDebt: err,
      };

    case AGENT_TO_MERCHANT_TRANSFER_BEGIN:
      return {
        ...state,
        loadingAgentToMerchantTransfer: true,
        errorAgentToMerchantTransfer: '',
      };
    case AGENT_TO_MERCHANT_TRANSFER_SUCCESS:
      return {
        ...state,
        loadingAgentToMerchantTransfer: false,
        errorAgentToMerchantTransfer: '',
      };
    case AGENT_TO_MERCHANT_TRANSFER_ERR:
      return {
        ...state,
        loadingAgentToMerchantTransfer: false,
        errorAgentToMerchantTransfer: err,
      };
    case CHANGE_TOPUP_REQUEST_STATUS_BACK_TO_PENDING_BEGIN:
      return {
        ...state,
        loadingChangeTopupRequestStatusToPending: true,
        errorChangeTopupRequestStatusToPending: '',
      };
    case CHANGE_TOPUP_REQUEST_STATUS_BACK_TO_PENDING_SUCCESS:
      return {
        ...state,
        loadingChangeTopupRequestStatusToPending: false,
        errorChangeTopupRequestStatusToPending: '',
      };
    case CHANGE_TOPUP_REQUEST_STATUS_BACK_TO_PENDING_ERR:
      return {
        ...state,
        loadingChangeTopupRequestStatusToPending: false,
        errorChangeTopupRequestStatusToPending: err,
      };

    default:
      return state;
  }
};

export { walletsReducer };
